import dynamic from 'next/dynamic';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { QueryClient, QueryClientProvider } from 'react-query';
import Layout from '@/components/Layout';
import { LoadUser } from '@/components/LoadUser';
import { useMixpanel } from '@/utils/client/useMixpanel';
import '@/assets/main.css';
import '@/assets/chrome-bug.css';

const queryClient = new QueryClient();

const MyApp = ({
  Component,
  pageProps
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Component: any;
  pageProps: JsonB;
}): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const UserContextProvider = dynamic(() =>
    import('@/utils/user/client/useUser').then(
      (module) => module.UserContextProvider
    )
  );
  const router = useRouter();

  const {
    function: { mixpanelInit }
  } = useMixpanel();

  useEffect(() => {
    document.body.classList?.remove('loading');

    if (router?.asPath?.indexOf('&token_type=bearer&type=recovery') > 0) {
      const access_token = router?.asPath
        ?.split('access_token=')[1]
        .split('&')[0];
      router.push(
        '/reset-password?passwordReset=true&access_token=' + access_token + ''
      );
    }
  }, [router]);

  useEffect(() => {
    mixpanelInit();
  }, [mixpanelInit]);

  return (
    <div>
      <QueryClientProvider client={queryClient}>
        {router.pathname.indexOf('/embed') > -1 ? (
          <Layout>
            <Component {...pageProps} />
          </Layout>
        ) : (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          <UserContextProvider>
            <Layout>
              <LoadUser Component={Component} props={pageProps} />
            </Layout>
          </UserContextProvider>
        )}
      </QueryClientProvider>
    </div>
  );
};

export default MyApp;
