import axios, { AxiosResponse } from 'axios';

export const axiosApiInstance = axios.create({
  headers: { 'Content-Type': 'application/json' }
});

export const postRequest = async <T>({
  url,
  data = {}
}: {
  url: string;
  data?: JsonB;
}): Promise<AxiosResponse<T>> => {
  return await axiosApiInstance.post<T, AxiosResponse<T>>(url, data);
};
