import { ArrowCircleRightIcon } from '@heroicons/react/outline';
import Link from 'next/link';
import Logo from '@/components/icons/Logo';
import { useUser } from '@/utils/user/client/useUser';

export const Navbar = (): JSX.Element => {
  const { user } = useUser();

  return (
    <div className="bg-primary">
      <div className="py-6 wrapper">
        <div className="flex justify-between">
          <div className="flex">
            <div className="flex-shrink-0 flex items-center">
              <Link href="/">
                <a>
                  <Logo white className="h-8 md:h-12 w-auto" />
                </a>
              </Link>
            </div>
          </div>

          <div className="hidden md:flex items-center">
            <nav className="flex items-center justify-center">
              <a
                href="/#features"
                className="text-md lg:text-lg text-white hover:underline mx-4 tracking-tight"
              >
                Features
              </a>
              <a
                href="/#how-it-works"
                className="text-md lg:text-lg text-white hover:underline mx-4 tracking-tight"
              >
                How It Works
              </a>
              <Link href="/pricing">
                <a className="text-md lg:text-lg text-white hover:underline mx-4 tracking-tight">
                  Pricing
                </a>
              </Link>
              <a
                href="https://blog.recover.so/"
                target="_blank"
                rel="noreferrer"
                className="text-md lg:text-lg text-white hover:underline mx-4 tracking-tight"
              >
                Blog
              </a>
            </nav>
          </div>

          <div className="flex items-center">
            {user ? (
              <div className="flex-shrink-0">
                <Link href="/dashboard">
                  <a className="relative inline-flex items-center px-4 py-2 border border-transparent text-xs md:text-sm font-medium rounded-md bg-white shadow-sm  focus:outline-none focus:ring-2 focus:ring-offset-2 ">
                    <span>Dashboard</span>
                    <ArrowCircleRightIcon
                      className="ml-2 h-5 w-5"
                      aria-hidden="true"
                    />
                  </a>
                </Link>
              </div>
            ) : (
              <div className="flex-shrink-0">
                <Link href="/signin">
                  <a className="text-white mr-4 text-xs md:text-sm hidden md:inline-block">
                    <span>Login</span>
                  </a>
                </Link>
                <Link href="/signup">
                  <a className="relative inline-flex items-center px-4 py-2 border border-transparent text-xs md:text-sm font-medium rounded-md text-primary bg-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 ">
                    <span>Get Started</span>
                    <ArrowCircleRightIcon
                      className="ml-2 h-5 w-5"
                      aria-hidden="true"
                    />
                  </a>
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
