import { useEffect } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { RenderNotice } from './RenderNotice';
import { Navbar } from '@/components/ui/Navbar/Navbar';
import { setSentryUser, useUser } from '@/utils/user/client/useUser';

const Layout = ({ children }: { children: JSX.Element }): JSX.Element => {
  const Toaster = dynamic(
    (): Promise<typeof import('react-hot-toast').Toaster> =>
      import('react-hot-toast').then((module) => module.Toaster)
  );
  const Footer = dynamic(() => import('@/components/ui/Footer'));
  const AdminMobileNav = dynamic(
    () => import('@/components/ui/AdminNavbar/AdminMobileNav')
  );
  const AdminDesktopNav = dynamic(
    () => import('@/components/ui/AdminNavbar/AdminDesktopNav')
  );
  const SimpleNav = dynamic(() => import('@/components/ui/SimpleNav'));
  const router = useRouter();
  const { session } = useUser();

  useEffect(() => {
    setSentryUser(session?.user);
  }, [session]);

  const renderAdminNav = (): JSX.Element => {
    return (
      <div className="h-screen flex overflow-hidden">
        <AdminDesktopNav />
        <div className="flex-1 overflow-auto focus:outline-none">
          <RenderNotice />
          <AdminMobileNav />
          <main className="wrapper py-12" id="skip">
            {children}
          </main>
        </div>
      </div>
    );
  };

  const conditionalLayout = (): JSX.Element => {
    const isDashboard = router.pathname.indexOf('/dashboard') > -1;

    if (isDashboard) {
      return renderAdminNav();
    }

    const isLogoLayout = router.pathname.indexOf('add-account') > -1;

    if (isLogoLayout) {
      return (
        <>
          <SimpleNav />
          <main id="skip">{children}</main>
        </>
      );
    }

    const isEmptyLayout =
      router.pathname.indexOf('/pnl') > -1 ||
      router.pathname.indexOf('/embed') > -1 ||
      router.pathname === '/checkout/campaign/[campaignEmailLogId]';

    if (isEmptyLayout) {
      return <main id="skip">{children}</main>;
    }

    return (
      <>
        <Navbar />
        <main id="skip">{children}</main>
        <Footer />
      </>
    );
  };

  return (
    <>
      <Toaster
        position="bottom-center"
        reverseOrder={true}
        gutter={20}
        toastOptions={{
          className: '',
          duration: 5000,
          style: {
            background: '#fff',
            color: '#111827'
          },
          // Default options for specific types
          success: {
            theme: {
              primary: 'green',
              secondary: 'black'
            }
          },
          error: {
            style: {
              background: '#DC2626',
              color: 'white'
            }
          }
        }}
      />
      {conditionalLayout()}
    </>
  );
};

export default Layout;
