import {
  PostgrestResponse,
  PostgrestSingleResponse,
  Session
} from '@supabase/supabase-js';
import { useEffect, useState, createContext, useContext } from 'react';
import * as Sentry from '@sentry/nextjs';
import { axiosApiInstance, postRequest } from '../../apiCall';
import { StripeAccount } from '../../stripe/models/stripeAccount';
import { Subscription } from '../../stripe/models/subscription';
import { SupabaseUser, User } from '../model';
import { supabaseClient } from '../../client/supabase';
import { Price } from '@/utils/stripe/models/price';
import { Product } from '@/utils/stripe/models/product';
import { useMixpanel } from '@/utils/client/useMixpanel';

type SubscriptionResponse = Subscription & {
  prices: Price & {
    products: Product;
  };
};

interface UserContextValue {
  session: Maybe<Session>;
  user: Maybe<SupabaseUser>;
  userDetails: Maybe<User>;
  userLoaded: boolean;
  stripeAccounts: Maybe<StripeAccount[]>;
  selectedStripeAccount: Maybe<StripeAccount>;
  subscription: Maybe<SubscriptionResponse>;
  userFinderLoaded: boolean;
  updateSelectedStripeAccount: (stripeId: string) => void;
  signIn: typeof supabaseClient.auth.signIn;
  signUp: typeof supabaseClient.auth.signUp;
  forgotPassword: typeof supabaseClient.auth.api.resetPasswordForEmail;
  signOut: typeof supabaseClient.auth.signOut;
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const UserContext = createContext<UserContextValue>({
  session: null,
  user: null,
  userDetails: null,
  userLoaded: false,
  stripeAccounts: [],
  selectedStripeAccount: null,
  subscription: null,
  userFinderLoaded: false
});

export const setSentryUser = (user: MaybeAll<SupabaseUser>): void => {
  Sentry.setUser(
    user
      ? {
          id: user.id,
          email: user.email
        }
      : null
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const UserContextProvider = (props: any): React.ReactNode => {
  const [userLoaded, setUserLoaded] = useState<boolean>(false);
  const [session, setSession] = useState<Maybe<Session>>(null);
  const [user, setUser] = useState<Maybe<SupabaseUser>>(null);
  const [userFinderLoaded, setUserFinderLoaded] = useState<boolean>(false);
  const [userDetails, setUserDetails] = useState<Maybe<User>>(null);
  const [subscription, setSubscription] =
    useState<Maybe<SubscriptionResponse>>(null);
  const [stripeAccounts, setStripeAccounts] =
    useState<Maybe<StripeAccount[]>>(null);
  const [selectedStripeAccount, setSelectedStripeAccount] =
    useState<Maybe<StripeAccount>>(null);
  const {
    function: { linkMixpanelUser }
  } = useMixpanel();

  useEffect(() => {
    const session = supabaseClient.auth.session();
    setSession(session);
    setUser(session?.user ?? null);
    setSentryUser(session?.user);

    const { data: authListener } = supabaseClient.auth.onAuthStateChange(
      async (_, session) => {
        setSentryUser(session?.user);

        setSession(session);
        setUser(session?.user ?? null);
      }
    );

    setUserFinderLoaded(true);

    return (): void => {
      if (authListener) {
        authListener.unsubscribe();
      }
    };
  }, []);

  const getUserDetails = (): PromiseLike<PostgrestSingleResponse<User>> =>
    supabaseClient.from<User>('users').select('*').single();

  const getSubscription = (): PromiseLike<
    PostgrestSingleResponse<SubscriptionResponse>
  > =>
    supabaseClient
      .from<SubscriptionResponse>('subscriptions')
      .select('*, prices(*, products(*))')
      .in('status', ['trialing', 'active'])
      .single();

  const getUserStripeAccounts = (): PromiseLike<
    PostgrestResponse<StripeAccount>
  > => supabaseClient.from<StripeAccount>('stripe_accounts').select('*');

  useEffect(() => {
    if (user) {
      Promise.allSettled([
        getUserDetails(),
        getSubscription(),
        getUserStripeAccounts()
      ]).then((results) => {
        if (results[0].status === 'fulfilled') {
          setUserDetails(results[0].value.data);
        }
        if (results[1].status === 'fulfilled') {
          setSubscription(results[1].value.data);
        }
        if (results[2].status === 'fulfilled') {
          setStripeAccounts(results[2].value.data);
        }

        setUserLoaded(true);
        setUserFinderLoaded(true);
      });
    }
  }, [user]);

  useEffect(() => {
    if (user) linkMixpanelUser({ userDetails, user, subscription });
  }, [userDetails, user, subscription, linkMixpanelUser]);

  const value: UserContextValue = {
    session,
    user,
    userDetails,
    userLoaded,
    stripeAccounts,
    selectedStripeAccount,
    subscription,
    userFinderLoaded,
    updateSelectedStripeAccount: (stripeId) => {
      const val = stripeAccounts?.find((a) => a.stripe_id === stripeId) || null;
      setSelectedStripeAccount(val);
    },
    signIn: (options) =>
      supabaseClient.auth.signIn(options).then((res) => {
        if (res.session) {
          axiosApiInstance.defaults.headers.common.authorization =
            res.session.access_token;
          setSentryUser(res.session.user);
          postRequest({
            url: '/api/user-action',
            data: {
              action: 'signIn'
            }
          }).catch(() => {
            console.error('error');
          });
        }
        return res;
      }),
    signUp: (options) =>
      supabaseClient.auth.signUp(options).then((res) => {
        if (res.session) {
          axiosApiInstance.defaults.headers.common.authorization =
            res.session.access_token;
          setSentryUser(res.session.user);
          postRequest({
            url: '/api/user-action',
            data: {
              action: 'signUp'
            }
          }).catch(() => {
            console.error('error');
          });
        }
        return res;
      }),
    forgotPassword: (email) =>
      supabaseClient.auth.api.resetPasswordForEmail(email),
    signOut: () => {
      setUserDetails(null);
      setSubscription(null);
      delete axiosApiInstance.defaults.headers.common.authorization;
      return supabaseClient.auth.signOut();
    }
  };
  return <UserContext.Provider value={value} {...props} />;
};

export const useUser = (): UserContextValue => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error(`useUser must be used within a UserContextProvider.`);
  }
  return context;
};
