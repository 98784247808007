import {
  PostgrestResponse,
  PostgrestSingleResponse
} from '@supabase/supabase-js';
import { CampaignConfig, CampaignConfigForm } from '../models/config';
import {
  CampaignEmailConfig,
  CreateCampaignEmailConfig
} from '../models/emailConfig';
import { supabaseClient } from '../../client/supabase';
import { CampaignLog } from '../models/log';
import {
  PostgrestResponseSuccess,
  processSupabaseResponse
} from '@/utils/supabase/helpers';
import { StripeAccount } from '@/utils/stripe/models/stripeAccount';

export const getConfigs = async (
  stripeId: string
): Promise<PostgrestResponse<CampaignConfig>> => {
  return await supabaseClient
    .from<CampaignConfig>('campaign_config')
    .select('*')
    .eq('stripe_id', stripeId);
};

export const createConfig = async (
  data: CampaignConfigForm,
  stripeAccount: StripeAccount
): Promise<PostgrestSingleResponse<CampaignConfig>> => {
  return await supabaseClient
    .from<CampaignConfig>('campaign_config')
    .insert({
      ...data,
      stripe_id: stripeAccount.stripe_id,
      team_id: stripeAccount.team_id,
      updated_at: new Date()
    })
    .single();
};

export const getConfig = async (
  id: string,
  stripeId: string
): Promise<PostgrestSingleResponse<CampaignConfig>> => {
  return await supabaseClient
    .from<CampaignConfig>('campaign_config')
    .select('*')
    .eq('id', id)
    .eq('stripe_id', stripeId)
    .single();
};

export const updateConfig = async (
  campaignConfigId: string,
  data: Partial<CampaignConfigForm>
): Promise<PostgrestSingleResponse<CampaignConfig>> => {
  return await supabaseClient
    .from<CampaignConfig>('campaign_config')
    .update({
      ...data,
      updated_at: new Date()
    })
    .match({ id: campaignConfigId })
    .single();
};

export const deleteConfig = async (
  id: string
): Promise<PostgrestSingleResponse<CampaignConfig>> => {
  await supabaseClient
    .from<CampaignEmailConfig>('campaign_email_config')
    .delete()
    .match({ campaign_config_id: id });

  return await supabaseClient
    .from<CampaignConfig>('campaign_config')
    .delete()
    .match({ id })
    .single();
};

export const getEmailConfigs = async (
  campaignId: string
): Promise<PostgrestResponse<CampaignEmailConfig>> => {
  return await supabaseClient
    .from<CampaignEmailConfig>('campaign_email_config')
    .select('*')
    .eq('campaign_config_id', campaignId)
    .order('created_at', {
      ascending: true
    });
};

export const getEmailConfig = async (
  emailConfigId: string
): Promise<PostgrestSingleResponse<CampaignEmailConfig>> => {
  return await supabaseClient
    .from<CampaignEmailConfig>('campaign_email_config')
    .select('*')
    .eq('id', emailConfigId)
    .single();
};

export const createEmailConfig = async (
  data: CreateCampaignEmailConfig
): Promise<PostgrestSingleResponse<CampaignEmailConfig>> => {
  return await supabaseClient
    .from<CampaignEmailConfig>('campaign_email_config')
    .insert({
      ...data,
      updated_at: new Date()
    })
    .single();
};

export const updateEmailConfig = async (
  id: string,
  data: Partial<CreateCampaignEmailConfig>
): Promise<PostgrestSingleResponse<CampaignEmailConfig>> => {
  return await supabaseClient
    .from<CampaignEmailConfig>('campaign_email_config')
    .update({
      ...data,
      updated_at: new Date()
    })
    .match({ id })
    .single();
};

export const deleteEmailConfig = async (
  id: string
): Promise<PostgrestSingleResponse<CampaignEmailConfig>> => {
  return await supabaseClient
    .from<CampaignEmailConfig>('campaign_email_config')
    .delete()
    .match({ id })
    .single();
};

export const getCampaignLogs = async (
  stripeId: string
): Promise<PostgrestResponseSuccess<CampaignLog>> => {
  return processSupabaseResponse(
    await supabaseClient
      .from<CampaignLog>('campaign_log')
      .select('*, campaignConfig:campaign_config_id!inner ( id )', { count: 'exact' })
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      .eq('campaignConfig.stripe_id', stripeId)
  );
};
