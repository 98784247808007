import { useRouter } from 'next/router';
import { useQuery } from 'react-query';
import { getCampaignLogs } from '@/utils/campaign/client/supabase';
import { getEmailData } from '@/utils/email/client/supabase';
import { useUser } from '@/utils/user/client/useUser';

export const RenderNotice = (): JSX.Element | null => {
  const router = useRouter();
  const { userFinderLoaded, subscription } = useUser();

  const getCampaignLogsQuery = useQuery(
    'getCampaignLogs',
    () => getCampaignLogs(router.query.stripeId as string),
    {
      enabled: !!router.query.stripeId
    }
  );

  const getEmailDataQuery = useQuery(
    'getEmailData',
    () => getEmailData(router.query.stripeId as string),
    {
      enabled: !!router.query.stripeId
    }
  );

  const showNotice =
    userFinderLoaded &&
    subscription === null &&
    getCampaignLogsQuery.isSuccess &&
    getEmailDataQuery.isSuccess &&
    getCampaignLogsQuery.data.data.length +
      getEmailDataQuery.data.data.length >=
      10;

  if (showNotice) {
    return (
      <div className="w-full py-3 bg-red text-white text-center">
        <p>
          You have now reached your free limit of 10 Recover email sends. Please{' '}
          <a
            className="font-bold underline"
            href={`/dashboard/${router.query.stripeId}/plan`}
          >
            upgrade your account
          </a>{' '}
          to continue sending.
        </p>
      </div>
    );
  }

  return null;
};
