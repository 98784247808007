import {
  PostgrestResponse,
  PostgrestSingleResponse
} from '@supabase/supabase-js';

interface PostgrestResponseSuccessBase<D> {
  status: number;
  statusText: string;
  error: null;
  data: D;
  body: D;
}

export type PostgrestSingleResponseSuccess<T> = PostgrestResponseSuccessBase<T>;

export interface PostgrestResponseSuccess<T>
  extends PostgrestResponseSuccessBase<T[]> {
  count: number | null;
}

export const processSupabaseResponse = <T>(
  response: PostgrestResponse<T>
): PostgrestResponseSuccess<T> => {
  if (response.error) {
    console.error(response.error.message);
    throw response.error;
  }

  return response;
};

export const processSingleSupabaseResponse = <T>(
  response: PostgrestSingleResponse<T>
): PostgrestSingleResponseSuccess<T> => {
  if (response.error) {
    console.error(response.error.message);
    throw response.error;
  }

  return response;
};
