import { supabaseClient } from '@/utils/client/supabase';
import { Email } from '@/utils/email/model';
import { PostgrestResponseSuccess, processSupabaseResponse } from '@/utils/supabase/helpers';

//Grab email data
export const getEmailData = async (
  stripeId: string
): Promise<PostgrestResponseSuccess<Email>> => {
  return processSupabaseResponse(
    await supabaseClient
      .from<Email>('emails')
      .select('*')
      .eq('stripe_id', stripeId)
  );
};
