import { AuthUser } from '@supabase/supabase-js';
import mixpanel from 'mixpanel-browser';
import { useState } from 'react';
import { getCampaignLogs } from '../campaign/client/supabase';
import { Subscription } from '../stripe/models/subscription';
import { useUser } from '../user/client/useUser';
import { User } from '../user/model';

enum MixpanelEvents {
  pnlCreated = 'pnlCreated',
  pnlVisted = 'pnlVisted',
  signIn = 'userSignIn',
  signUp = 'userSignUp',
  campaignRecoveredRevenueAndLogs = 'campaignRecoveredRevenueAndLogs'
}

interface MixpanelUserData {
  $email: string;
  $created: string;
}

interface CampaignLogsAmountData {
  totalCampaignRecoveredRevenue: number;
  totalCampaignLog: number;
}

interface useMixpanelReturnData {
  function: {
    mixpanelInit: () => void;
    linkMixpanelUser: (linkUserData: LinkUserData) => void;
    userSignIn: () => void;
    userSignUp: () => void;
    pnlCreated: (displayName: string, isActive: boolean) => void;
    pnlVisted: (displayName: string) => void;
    campaignLogsTotals: () => void;
  };
}
interface LinkUserData {
  userDetails: Maybe<User>;
  user: AuthUser;
  subscription: Maybe<Subscription>;
}

export const useMixpanel = (): useMixpanelReturnData => {
  const { user, userDetails, subscription, selectedStripeAccount } = useUser();
  const [inited, setInited] = useState(false);
  const getCampaignLogsAmounts = async (): Promise<
    CampaignLogsAmountData | undefined
  > => {
    try {
      if (!selectedStripeAccount) throw 'no Stripe account found';

      const data = await getCampaignLogs(selectedStripeAccount.stripe_id);

      const totalCampaignRecoveredRevenue = data?.data.reduce(
        (acc, campaignLog) => {
          if (campaignLog.status === 'recovered')
            return acc + campaignLog.amount_in_usd;
          return acc;
        },
        0
      );

      return {
        totalCampaignRecoveredRevenue,
        totalCampaignLog: data.data.length
      };
    } catch (e) {
      console.error(e);
    }
  };

  const mixpanelInit = (): void => {
    if (process.env.NEXT_PUBLIC_MIXPANEL_KEY && !inited) {
      mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_KEY);
      setInited(!inited);
    }
  };

  const mixpanelTracking = (event: MixpanelEvents, data: JsonB = {}): void => {
    if (process.env.NEXT_PUBLIC_MIXPANEL_KEY) {
      mixpanel.track(event, data);
    }
  };

  const createMixpanelUser = (id: string, data: MixpanelUserData): void => {
    if (process.env.NEXT_PUBLIC_MIXPANEL_KEY) {
      {
        mixpanel.clear_opt_in_out_tracking();
        mixpanel.alias(id);
        mixpanel.people.set(data);
      }
    }
  };

  const linkMixpanelUser = (linkUserData: LinkUserData): void => {
    const { userDetails, user: authUserData, subscription } = linkUserData;
    if (process.env.NEXT_PUBLIC_MIXPANEL_KEY && authUserData && userDetails) {
      {
        mixpanel.clear_opt_in_out_tracking();
        mixpanel.identify(authUserData.id);
        mixpanel.people.set({
          $email: authUserData.email,
          $name: userDetails.full_name,
          $created: authUserData.created_at,
          last_login: authUserData.last_sign_in_at,
          subscription: subscription?.status ?? null
        });

        mixpanel.register({
          _userEmail: authUserData.email,
          _userName: userDetails.full_name,
          _userCreatedAt: authUserData.created_at,
          _userLastLogin: authUserData.last_sign_in_at
        });
      }
    }
  };

  const userSignIn = (): void => {
    if (user) {
      linkMixpanelUser({ userDetails, user, subscription });
      mixpanelTracking(MixpanelEvents.signIn);
    }
  };

  const userSignUp = (): void => {
    if (user) {
      createMixpanelUser(user.id, {
        $email: user.email as string,
        $created: user.created_at
      });
      mixpanelTracking(MixpanelEvents.signUp);
    }
  };

  const pnlCreated = (displayName: string, isActive: boolean): void => {
    mixpanelTracking(MixpanelEvents.pnlCreated, {
      display_name: displayName,
      is_active: isActive
    });
  };

  const pnlVisted = (displayName: string): void => {
    mixpanelTracking(MixpanelEvents.pnlVisted, {
      display_name: displayName
    });
  };

  const campaignLogsTotals = (): void => {
    if (selectedStripeAccount?.stripe_id)
      getCampaignLogsAmounts().then((data) => {
        if (data?.totalCampaignLog && data?.totalCampaignRecoveredRevenue)
          mixpanelTracking(MixpanelEvents.campaignRecoveredRevenueAndLogs, {
            totalCampaignRecoveredRevenue: data.totalCampaignRecoveredRevenue,
            totalCampaignLog: data.totalCampaignLog,
            stripeId: selectedStripeAccount.stripe_id
          });
      });
  };

  return {
    function: {
      mixpanelInit,
      linkMixpanelUser,
      userSignIn,
      userSignUp,
      pnlCreated,
      pnlVisted,
      campaignLogsTotals
    }
  };
};
