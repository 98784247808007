import { useEffect, useState } from 'react';
import LoadingDots from './ui/LoadingDots';
import { supabaseClient } from '@/utils/client/supabase';
import { axiosApiInstance } from '@/utils/apiCall';

export const LoadUser = ({
  Component,
  props
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Component: any;
  props: JsonB;
}): JSX.Element => {
  const [userFinderLoaded, setUserFinderLoaded] = useState<boolean>(false);

  useEffect(() => {
    const session = supabaseClient.auth.session();

    if (session) {
      axiosApiInstance.defaults.headers.common.authorization = session.access_token;
    }

    setUserFinderLoaded(true);
  }, [setUserFinderLoaded]);

  if (!userFinderLoaded) {
    return <LoadingDots />;
  }

  return <Component {...props} />;
};
